body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.consent_ul{
  padding-left: 40px;
  margin-top: 20px;
}

ul {
  margin: 0;
  padding-inline-start: 16px;
}

.radio_item{
  display: none !important;
  }

.label_item {
  border-style: none;
}
    
.radio_item:checked + label {
  border-style: solid;
}

#chat_container {
  height: 400px;
  padding: 0;
  margin: 0;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

#consent_text{

  padding: 20px;
  margin: 20px;
  background: white !important;
}

.survey{
  margin: 20px;
  padding-top: 20px;
  padding-right: 0px;
  padding-bottom: 20px;
  padding-left: 0px;
  background: white !important;
}

.sv-table {
  table-layout: fixed;
}

th,
td {
  overflow: hidden;
}

.sv-body__page, .sv-body__footer {
  margin-right: 2% !important;
  margin-left: 2% !important;
}

.sv-text {
  width: 10% !important;
}

.sv-row:not(:last-child) {
  padding-bottom: 1em !important;
}
.sv-table__cell{
  padding-top: 0.5em !important;
  padding-right: 0px;
  padding-bottom: 0.5em !important;
  padding-left: 0px;}
  
  .sv-table td:first-child, .sv-table th:first-child {
    padding-left: 0.5em !important;
  }
  
  .sv-table tr:first-child .sv-table__cell {
    padding-top: 1 em !important;
  }
  
  .sv-table td:last-child, .sv-table th:last-child {
    padding-right: 0em !important;
  }
  
  .sv-table__cell:not(:first-child) {
    padding-left: 0em !important;
  }
  .sv-table__cell:not(:last-child) {
    padding-right: 0em !important;
  }

  .sv-body__page, .sv-body__footer {
    margin-right: 2% !important;
    margin-left: 2% !important;
  }

  .noUi-target {
    margin-bottom: 0px !important;
  }

.arrow_buttons{
  min-width:20px !important;
  background:white !important;
  border:none !important;
  font-size: inherit;

}

.sv_image_image{
display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.sv-container-modern__title{
  padding-top: 0pt !important;
}

.sv-page__title{
  margin-bottom: 1.5pt !important;
}