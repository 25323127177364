.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wipe-reveal-right {
  overflow: hidden;
  position: relative;
}

.wipe-reveal-right::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 2;
  transition: all 1s linear;
}

.wipe-reveal-right.animate::after {
  transform: translateX(100%);
}

.fade-disappear-up {
  transition: all 3s ease-in;
}

.fade-disappear-up.animate {
  transform: translateY(-100%);
  opacity: 0;
}

.str-chat__container{
  background:#EFEEEE;
  height: 100%;
}
.str-chat__input-flat{
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding:none;
  background:#EFEEEE;
}

.str-chat__list {
 padding: none;
 border-top-left-radius: 4px;
 border-top-right-radius: 4px;
 height: 100%;
}
.str-chat-channel.messaging .str-chat__main-panel {
  padding: 0;
  height: 100%;
}

.str-chat.messaging, .str-chat.commerce {
  background-color: #EFEEEE;
  border-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.str-chat-channel .str-chat__container {
  height: 100%;
  width: 100%;
  display: flex;
  border-radius: 20px;
}


.messaging.str-chat .str-chat__list {
  padding: 0 !important;
  background: var(--gray);
  height: 100%;
}

.str-chat__message--me .str-chat__message-attachment--img,
.str-chat__message-simple--me .str-chat__message-attachment--img {
  height: 500px;
}

.str-chat__message-text-inner, .str-chat__message-simple-text-inner .str-chat__li--bottom .str-chat__message-text-inner, .str-chat__li--middle .str-chat__message-text-inner{
  position: relative;
  flex: 1 1;
  display: block;
  min-height: 32px;
  padding: var(--xxs-p) var(--xs-p);
  font-size: var(--lg-font);
  color: var(--black);
  border-radius: 0 var(--border-radius) var(--border-radius) var(--border-radius) !important;
  background: var(--white-snow);
  border: 1px solid var(--border);
  margin-left: 0;
}

@media screen and (max-width: 960px){
str-chat-channel.messaging str-chat__main-panel {
    padding: 0 !important;
}}

.message-header-name{
  font-size: 10px;
  text-transform: uppercase;
}
.message-header{
  text-align: center;
  margin-top:0px !important;
  
}
#user_details{
  margin-right: 0.5rem;
}
.str-chat__avatar{
  margin-right: 0 !important;
}
.message-wrapper-content {
  margin: 8px;
}

@media screen and (max-width: 1000px){
.messaging.str-chat .str-chat__list .str-chat__reverse-infinite-scroll {
    padding-top: 20px;
    height: 100%;
}}

.str-chat-channel {
  height: 70vh;
  width: 30vw;
}

@media screen and (max-width: 1000px){
.str-chat-channel {
  height: 35vh;
  width: 80vw;
}}

@media screen and (max-width: 960px) {
  str-chat.messaging str-chat__input-flat {
    padding: 0 !important;
    height: 100%;
  }}

  
  .str-chat__send-button {margin:1}

.str-chat__input-flat-emojiselect {

  left: 5px;
  top: calc(100% - 55px);
}
.str-chat__input-flat .str-chat__textarea>textarea {
  padding-top: 15px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 35px;
    background: rgb(228, 227, 227);
}
input{border-width: thin;}

.str-chat.messaging .str-chat__input-flat {
      padding-top: 2px;
      padding-bottom: 2px;
      padding-left: 10px;
      padding-right: 10px;
}

.suggestion-header {  font-weight: 700;  font-size: 16px;}
.suggestion-item {  display: flex;  align-items: center;  height: 32px;  font-size: 18px;  padding-left: 16px;}
.suggestion-item.selected {  background: var(--white-smoke);  color: var(--primary-color);  font-weight: 700;  cursor: pointer;}

.suggestion_box{ display: flex;  align-items: center; flex-direction: row; flex-wrap:wrap; justify-content:space-evenly !important; width:100% !important}
.suggestion_button{
  background-color: #4CAF50; /* Green */
  border: none;
  color: #466a46;
  padding: 5px 5px !important;
  text-align: center;
  text-decoration: none;
  max-height: 40px !important;
  font-size: 16px !important;
  margin:1% !important;
  border-radius: 15px !important;
  display: inline-block !important;
  width:fit-content !important;
}
.suggestionbox_close_button{
  position:absolute !important;
  top:0 !important;
  right:0 !important;
  padding: 0px !important;
border:none !important;
font-size: 15px!important;
height: fit-content !important;
width: fit-content !important;
background: none !important;}

.noUi-handle{display:none}
